$platform-name: 'voluntaryartsredesign';

// Colours
// Primary
$gold: #FFCC00;
$teal-dark: #008C94;
$teal: $teal-dark; // Previously #41D7D8, but client stopped using this.
$dark-blue: #032B43;

// Secondary

$gold-dark: #EB9700;
$pink: #E75294;

$brand-primary: $teal-dark;
$brand-secondary: $teal;

$donate-colour: $gold;

// Create greyscale
$black: #000 !default;
$grey-dark: #4B4B4B;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #eceeef !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

// Logo
$logo: "logo.svg";
$logo-width: 300px;
$logo-height: 60px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;

// Spacers
$spacer: 1rem;

// Typography
$font-family-base: 'Work Sans', sans-serif;
$font-size-base: 18px;
$headings-font-family: $font-family-base;
$font-weight-bold: 700;

$headings-line-height: 1.3;

// Buttons
$btn-border-radius: 100px;
$btn-font-weight: bold;

// Header content
$header-content-max-width: 100%;
$header-background-colour: white;
$header-content-margin-top: $spacer;
$header-content-margin-bottom: $spacer;

$menu-admin-enabled: false;

$header-sticky-social-icons-enabled: true;

// Search
// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-height: 44px;
$header-search-input-border-radius: $btn-border-radius;

// Search - button
$header-search-button-border-radius: $btn-border-radius;

// Carousel
$carousel-max-width: 100%;
$carousel-image-overlay: rgba(#000, .5);
$carousel-details-background-colour: transparent;
$carousel-details-position-x: center;
$carousel-details-position-y: center;
$carousel-details-text-align: center;
$carousel-details-max-width: 800px;

$carousel-heading-colour: #fff;
$carousel-heading-font-size: 2.5em;

$carousel-summary-colour: #fff;
$carousel-summary-font-size: 1.25em;

$carousel-read-more-enabled: true;
$carousel-read-more-font-size: 1em;

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-icon-font-size: 3em;

// Sticky header
$header-background-colour: #fff;
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-logo-width: $logo-width;
$header-sticky-logo-height: $logo-height;
$header-sticky-header-content-margin-top: $header-content-margin-top;
$header-sticky-header-content-margin-bottom: $header-content-margin-bottom;
$header-sticky-search-enabled: true !default;

// Navigation
$nav-background-colour: transparent;
$nav-font-family: $headings-font-family;
$nav-breakpoint: map-get($breakpoints, lg) !default;

// Top level items
$nav-top-level-item-padding: 10px 10px !default;
$nav-top-level-item-colour: $grey-dark;
$nav-top-level-item-font-weight: normal;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;

// Submenus
$nav-submenu-background-colour: white;
$nav-submenu-item-colour: $black;

// Nav normal
$nav-normal-contents-max-width: 100%;
$nav-normal-align-items: right; // left, center, right
$nav-normal-margin-top: 25px !default;
$nav-normal-margin-bottom: 25px !default;

// Cards
$card-hover-image-opacity: 1;

// Home intro
$home-intro-padding-y: 0;
$home-intro-background-colour: white;
$home-intro-colour: text-contrast($brand-primary);

// Features
$home-features-padding-bottom: 0;

// Stats
$impact-stats-background-colour: white;
$impact-stats-padding-y: 0;
$impact-stats-colour: text-contrast($brand-primary);

// Feeds
$feeds-title-text-align: center;
$home-feeds-padding-bottom: 0;

// Footer
$footer-boxes-count: 4;
$footer-prefab: 1;
$footer-background-colour: $dark-blue;
$footer-colour: white;
$footer-link-colour: $footer-colour;

$footer-margin-y: $spacer * 5;

// Subsites
$subsite-enabled: true;

// Sidebar
$sidebar-layout: below;
$associated-max-number-of-posts: 3;

$associated-item-image-enabled: true;
$associated-item-summary-enabled: true;